import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import i18n from "i18next";
import {
  FooterSection,
  LanguageSwitch,
  LanguageSwitchContainer,
  LogoContainer,
  NavLink,
  SocialContainer,
} from "./styles";

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  interface SocialLinkProps {
    href: string;
    src: string;
  }
  
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
          <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo-brief.svg"
                  aria-label="homepage"
                  width="57px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <Col lg={6} md={6} sm={12} xs={12}></Col>
            <SocialContainer>
              <SocialLink
                href="https://www.linkedin.com/company/lunaestelar"
                src="linkedin.svg"
              />
            </SocialContainer>
            <Col lg={6} md={6} sm={12} xs={12}>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
